import React from 'react'
import styled from 'styled-components'
// eslint-disable-next-line no-unused-vars
import { graphql } from 'gatsby'
import PageLayout from '../../layouts/PageLayout/PageLayout'
import BlockCallToAction from '../../components/ACF/BlockCallToAction/BlockCallToAction'
import BlockTextImage from '../../components/ACF/BlockTextImage/BlockTextImage'
import BlockIcons from '../../components/ACF/BlockIcons/BlockIcons'
import { getBlockTextImageContent, getIconBlocksContent, getTitleBlockContent } from './data'
import BlockTitle from '../../components/ACF/BlockTitle/BlockTitle'
import KeysTable from '../../components/keysTable/KeysTable'
import availableKeys from '../../../content/assets/data/possibleKeys.json'

const KeyServicesPageTemplate = ({ data }) => {
	const brandName = data.brand.name

	const keyData = {
		seo: {
			canonical: `/autosleutels/${data.brand.slug}`,
			cornerstone: false,
			focuskw: `Autosleutels ${brandName}`,
			metaDesc: `Wilt u een extra autosleutel voor uw ${brandName} of heeft u een probleem met uw autosleutel van uw ${brandName}? `,
			metaKeywords: `Autosleutels ${brandName} - ECU adaptions`,
			metaRobotsNofollow: 'follow',
			metaRobotsNoindex: 'index',
			opengraphTitle: `autosleutels ${brandName} - ECU adaptions`,
			title: `autosleutels ${brandName} - ECU adaptions`
		},
		header: {
			backgroundColor: '#000000',
			backgroundOpacity: 0.2,
			fieldGroupName: 'header',
			height: 50,
			heroChoice: 'image',
			heroText: data?.brand?.name,
			isFrontPage: false,
			image: data?.brand?.image,
			pxOrVh: 'vh',
			videoUrl: null
		},
		...data?.brand
	}

	const ctaData = { callToAction: data.wpCtaPost }

	const keyServicesTextImageContent = getBlockTextImageContent(brandName)
	const keyServicesIconBlocksContent = getIconBlocksContent(brandName)
	const keyServicesTitleBlockContent = getTitleBlockContent(brandName)

	return (
		<PageLayout pageData={keyData}>
			<BlockTextImage data={keyServicesTextImageContent} generated={true} />
			<BlockIcons data={keyServicesIconBlocksContent} generated={true} />
			<BlockTitle data={keyServicesTitleBlockContent} generated={true} />
			<KeysTable brand={data.brand.slug}></KeysTable>
			<BlockCallToAction data={ctaData} />
		</PageLayout>
	)
}

export default KeyServicesPageTemplate

export const query = graphql`
	query KeyServiceBrandQuery($id: String!) {
		brand(id: { eq: $id }) {
			id
			name
			slug
			image {
				localFile {
					childImageSharp {
						gatsbyImageData(width: 1920)
					}
				}
			}
		}
		wpCtaPost(slug: { eq: "offerte-aanvragen" }) {
			id
			title
			postContent {
				buttonText
				imageOverlay
				imageOverlayOpacity
				paddingBottom
				paddingTop
				text
				buttonLink {
					url
				}
				image {
					altText
					sourceUrl
					localFile {
						childImageSharp {
							gatsbyImageData(width: 1920)
						}
					}
				}
			}
		}
	}
`
